import * as React from "react";
import { IonButton, useIonRouter, IonIcon, IonSlides, IonSlide, IonModal, IonContent, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonInput, IonSegment, IonSegmentButton, IonLabel, IonHeader, IonTitle, IonToolbar, IonButtons, IonCheckbox, IonFooter } from '@ionic/react';
import { addOutline, arrowForward, ellipseOutline, removeOutline } from 'ionicons/icons';
import { Age, Skill, Skill2, SkiRentalItem, useSkiRentalItemContext } from "../RentalState";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { SkiRentalItemOverview } from "./SkiRentalItemOverview";

export const SkiRentalCheckoutDialog: React.FC = () => {
    const { setCheckoutShown, checkoutShown, submit } = useSkiRentalItemContext();
    const [ checkedInsurance, setCheckedInsurance ] = React.useState(false);
    const [ checkedAgb, setCheckedAgb ] = React.useState(false);
    const router = useIonRouter();

    return (
        <IonModal isOpen={checkoutShown}>
            <IonHeader translucent>
                <IonToolbar>
                    <IonTitle>Checkout / Ihre Reservierung</IonTitle>
                    <IonButtons slot="secondary">
                        <IonButton onClick={e => setCheckoutShown(false)}>Schließen</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={true}>
                <IonCard> 
                    <IonCardContent>
                        Bitte überprüfen Sie Ihre Auswahl und akzeptieren Sie unsere Versicherungs- und allgemeinen Geschäftsbedingungen.
                    </IonCardContent>
                </IonCard>
                <SkiRentalItemOverview />


            </IonContent>
                <IonFooter>
                    <IonItem>
                        <IonCheckbox checked={checkedInsurance} onClick={e => setCheckedInsurance(!checkedInsurance)} color="primary"></IonCheckbox>
                        <IonLabel>Ich akzeptiere die Versicherungsbedingungen</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonCheckbox checked={checkedAgb} onClick={e => setCheckedAgb(!checkedAgb)} color="primary"></IonCheckbox>
                        <IonLabel>Ich akzeptiere die allgemeinen Geschäftsbedingungen</IonLabel>
                    </IonItem>
                    <IonButton color="success" disabled={!checkedAgb || !checkedInsurance} type="submit" size="large" expand="block" onClick={e => {
                        submit();
                        router.push("/thankyou");
                    }}>Absenden</IonButton>
                </IonFooter>
        </IonModal>
    )
}