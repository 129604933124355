
import * as React from "react";
import { IonButton, useIonRouter, IonIcon, IonSlides, IonSlide, IonList, IonListHeader, IonLabel, IonGrid, IonRow, IonCol, IonItem, IonAvatar } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { SkiRentalItem, useSkiRentalItemContext } from "../RentalState";

export const SkiRentalItemOverview: React.FC = () => {
    const { skiRentalItems } = useSkiRentalItemContext();

    return (
        <IonList>
            <IonGrid>
                {skiRentalItems.map((skiRentalItem: SkiRentalItem) => (
                    <IonItem
                        key={skiRentalItem.id}
                        lines="none">
                        <IonAvatar slot="start">
                            <img src="../assets/icon/i_ski.png" />
                        </IonAvatar>
                        <IonLabel>
                            <h2>{skiRentalItem.owner}</h2>
                            <p>Gewicht: {skiRentalItem.weight} kg</p>
                            <p>Körpergröße: {skiRentalItem.height} cm</p>
                            <p>Alter: {skiRentalItem.age}</p>
                            <p>Skill: {skiRentalItem.skill} {skiRentalItem.skill2}</p>
                        </IonLabel>
                    </IonItem>
                ))}
            </IonGrid>
        </IonList>
    )
}