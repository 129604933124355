
import * as React from "react";
import { IonButton, useIonRouter, IonIcon, IonSlides, IonSlide, IonToolbar, IonFooter } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { SkiRentalItem, useSkiRentalItemContext } from "../ServiceState";
import { useServiceItemContext } from "../ServiceState";

export const ServiceItemAdd: React.FC = () => {
    const { serviceItems , setServiceItemDialogShown } = useServiceItemContext();

    return (
            <IonToolbar hidden={serviceItems.length == 0}>
                <IonButton expand="block" size="large" onClick={e => setServiceItemDialogShown(true)}>weiteren Service hinzufügen</IonButton>
            </IonToolbar>
    )
}