
import * as React from "react";
import { IonButton, useIonRouter, IonIcon, IonSlides, IonSlide, IonToolbar, IonFooter } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { SkiRentalItem, useSkiRentalItemContext } from "../RentalState";

export const SkiRentalItemAdd: React.FC = () => {
    const { skiRentalItems, setSkiRentalItemsDialogShown } = useSkiRentalItemContext();

    return (
            <IonToolbar hidden={skiRentalItems.length == 0}>
                <IonButton expand="block" size="large" onClick={e => setSkiRentalItemsDialogShown(true)}>weiteren Artikel hinzufügen</IonButton>
            </IonToolbar>
    )
}