//@ts-nocheck
import React, { useState } from 'react';
import { IonButton, IonProgressBar, IonHeader, IonToolbar, IonButtons, IonCard, IonModal, IonDatetime, IonContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonImg, IonItem, IonInput, IonLabel, IonSelect, IonSelectOption, IonPage, IonBackButton, IonTitle, IonSegment, IonSegmentButton, IonIcon, IonListHeader, IonText, IonFooter } from '@ionic/react';
import { Controller, SubmitHandler, useForm, useController } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { personCircle, search, star, ellipsisHorizontal, ellipsisVertical, pin, call, heart, addOutline, removeOutline, ellipseOutline } from 'ionicons/icons';
import { QRModal } from '../components/QRModal';
import './Rental.css';
import createAuth0Helper from '../components/Auth0Helper';
import { useEffect } from "react";

const Rental: React.FC = () => {

  const auth0Helper = createAuth0Helper();
  const [userInfo, setUserInfo] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    const response = await fetch('https://my.montana-digital.com/todo', requestOptions);
  };

  const [submitSuccessful, setSubmitSuccessful] = useState<boolean>(false);
  const [confirmationModalShown, setConfirmationModalShown] = useState<boolean>(false);
  
  const {
    handleSubmit,
    control,
    setValue,
    register,
    getValues,
    formState: { errors }
  } = useForm<{
    weight: Number;
    size: Number;
    experience: Number;
  }>({
    defaultValues: {
      weight: '',
      size: '',
      experience: '',
    }
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton text="Zurück">
              </IonBackButton>
            </IonButtons>
            <IonTitle slot='end'>Verleih</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent scrollEvents={true}>

          <IonCard>
            <IonCardContent>
              Wir benötigen die folgenden Informationen, um Ihre Bindungen für Ihre persönlichen Bedürfnisse anzupassen.
            </IonCardContent>
          </IonCard>

          <IonCardHeader>
            <IonCardTitle>Gewicht [kg]</IonCardTitle>
          </IonCardHeader>
          <IonItem>
            <IonInput
              placeholder='85'
              type="number"
              {...register('weight', {
                required: 'Diese Angabe wird benötigt.',
              })}
            />
            <ErrorMessage
              errors={errors}
              name="weight"
              as={<div style={{ color: 'red' }} />}
            />
          </IonItem>

          <IonCardHeader>
            <IonCardTitle>Körpergröße [cm]</IonCardTitle>
          </IonCardHeader>
          <IonItem>
            <IonInput
              placeholder='175'
              type="number"
              {...register('size', {
                required: 'Diese Angabe wird benötigt.',
              })}
            />
            <ErrorMessage
              errors={errors}
              name="size"
              as={<div style={{ color: 'red' }} />}
            />
          </IonItem>

          <IonCardHeader>
            <IonCardTitle>Skifahrertyp</IonCardTitle>
          </IonCardHeader>

          <IonSegment value="1">
            <IonSegmentButton value="1" class='ion-segment-button-large'>
              <IonLabel>Anfänger</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="2" class='ion-segment-button-large'>
              <IonLabel>Fortgeschritten</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="3" class='ion-segment-button-large'>
              <IonLabel>Profi</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <IonSegment value="2">
            <IonSegmentButton value="1" layout="icon-start">
              <IonIcon icon={addOutline} />
            </IonSegmentButton>
            <IonSegmentButton value="2" layout="icon-start">
              <IonIcon icon={ellipseOutline} />
            </IonSegmentButton>
            <IonSegmentButton value="3" layout="icon-start">
              <IonIcon icon={removeOutline} />
            </IonSegmentButton>
          </IonSegment>

          <IonCardHeader>
            <IonCardTitle>Alter</IonCardTitle>
          </IonCardHeader>

          <IonSegment value="1">
            <IonSegmentButton value="1" class='ion-segment-button-large'>
              <IonLabel>0-9</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="2" class='ion-segment-button-large'>
              <IonLabel>10-49</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="3" class='ion-segment-button-large'>
              <IonLabel>50+</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          <IonButton size="large" type="submit" expand="block" onClick={() => console.log("")}>Speichern</IonButton>
          <QRModal modalState={[confirmationModalShown, setConfirmationModalShown]} value={userInfo} />
        </IonContent>
      </IonPage>
    </form>
  );
};

export default Rental;
