import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';

const Service: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Zurück">
            </IonBackButton>
          </IonButtons>
          <IonTitle slot='end'>Werkstatt</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
    </IonContent>
  </IonPage>
  );
};

export default Service;
