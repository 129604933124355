import auth0 from 'auth0-js';
import React, { useState, useContext, createContext } from 'react';

export class Auth0Helper {
  private readonly webAuth: any;
  private authResult: any = null;
  private user: any = null;

  constructor() {
    this.webAuth = new auth0.WebAuth({
      clientID: 'xikyOfc57sjYXMGfiBF2eRVEf0NA0mbW',
      domain: 'montana.eu.auth0.com',
      redirectUri: `${window.location.origin}` + '/check-in/callback',
      audience: `https://my.montana-digital.com/api/orders`,
      scope: 'openid profile email read:current_user create:orders read:orders',
      responseType: 'token id_token',
    });
  }

  public passwordlessStart(email: string) {
    this.webAuth.passwordlessStart({
      connection: 'email',
      send: 'link',
      email: email
    }, function (err: any,res: any) {
      if (err) {
        return console.log(err);
      }
      console.log(res);
        // handle errors or continue
      }
    );
  }

  public loginWithRedirect() {
    this.webAuth.authorize(
      {
        redirectUri: `${window.location.origin}` + '/check-in/callback'
        //Any additional options can go here
      }, (err: any, authResult: any) => {
        this.authResult = authResult;
        if (err) {
          console.log(err);
        }
      }
    );
  }

  public handleRedirectCallback(hash: any, callback: any) {
    this.webAuth.checkSession({}, (err: any, authResult: any) => {
      if (window.location.hash.includes('access_token')) {
        if (authResult == null) {
          this.webAuth.parseHash({ hash: window.location.hash }, (err: any, authResult: any) => {
            if (err) {
              alert(err);
              return console.log(err);
            }
            this.authResult = authResult;
          });
        }
      }
      if (err) {
        callback(err);
      }
      this.authResult = authResult;
      callback(null, authResult);
    });
  }

  public getUserInfo(callback: (user: any, err: any) => void) {
    if (this.user == null && this.authResult != null) {
      this.webAuth.client.userInfo(this.authResult.accessToken, (err: any, user: any) => {
        if (err) {
          callback(null, err)
          return console.log(err);
        } else {
          this.user = user;
          callback(user, null);
        }
      });
    } else if (this.user != null) {
      callback(this.user, null);
    } else if (this.authResult == null) {
      callback(null, "login_required");
    }
  }

  public getAccessToken() {
    return this.authResult.accessToken;
  }
};

const helper = new Auth0Helper();

export const Auth0Context = createContext(helper);
export const useAuth0 = () => useContext(Auth0Context)

export default function createAuth0Helper() {
  return helper;
}

