import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import SkiService from '../components/SkiService';
import './SkiserviceLandingPage.css';

const SkiserviceLandingPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen scroll-y="true">
        <IonToolbar class="ion-toolbar-montana">
        </IonToolbar>
        <SkiService/>
      </IonContent>
    </IonPage>
  );
};

export default SkiserviceLandingPage;