
import * as React from "react";
import { IonButton, useIonRouter, IonIcon, IonSlides, IonSlide, IonList, IonListHeader, IonLabel, IonGrid, IonRow, IonCol, IonItem, IonAvatar } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { ServiceItem, useServiceItemContext } from "../ServiceState";

export const ServiceOverview: React.FC = () => {
    const { serviceItems, load } = useServiceItemContext();

    React.useEffect(()=>load(),[]);

    return (
        <IonList>
            <IonGrid>
                {serviceItems.map((serviceItem: ServiceItem) => (
                    <IonItem
                        key={serviceItem.id}
                        lines="none">
                        <IonAvatar slot="start">
                            <img src="../assets/icon/i_ski.png" />
                        </IonAvatar>
                        <IonLabel>
                            <h2>Abholschein: {serviceItem.reference}</h2>
                            <p>Kunde: {serviceItem.customerName}</p>
                            <p>Service: {serviceItem.articleId} {serviceItem.articleName}</p>
                            <p>Kommentar: {serviceItem.comment}</p>
                            <p>Status: {serviceItem.status}</p>
                            <p>Datum: {serviceItem.closedDate}</p>
                        </IonLabel>
                    </IonItem>
                ))}
            </IonGrid>
        </IonList>
    )
}