
import * as React from "react";
import { IonButton, useIonRouter, IonIcon, IonSlides, IonSlide } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { useSkiRentalItemContext } from "../RentalState";
import { useServiceItemContext } from "../ServiceState";

export const WelcomeSlides: React.FC = () => {
    const { skiRentalItems, skiRentalItemsDialogShown , setSkiRentalItemsDialogShown } = useSkiRentalItemContext();
    const { serviceItems, setServiceItemDialogShown } = useServiceItemContext();
    const router = useIonRouter();

    const slideOpts = {
        initialSlide: 0,
        speed: 400
      };

    return (
        <IonSlides hidden={skiRentalItems.length > 0 || serviceItems.length > 0} pager={true} options={slideOpts}>
            <IonSlide>
                <div className="slide">
                    <img src="./assets/logo2.png" />
                    <h2>Willkommen im Verleih</h2>
                    <p>Sie haben noch keine Artikel hinzugefügt.</p>
                    <IonButton fill="clear" onClick={e => setSkiRentalItemsDialogShown(true)}>Jetzt Artikel hinzufügen <IonIcon slot="end" icon={arrowForward}></IonIcon></IonButton>
                </div>
            </IonSlide>
            <IonSlide>
                <div className="slide">
                    <img src="./assets/logo2.png" />
                    <h2>Willkommen in der Werkstatt</h2>
                    <p>Sie haben noch keinen Service hinzugefügt.</p>
                    <IonButton fill="clear" onClick={e => setServiceItemDialogShown(true)}>Jetzt Service hinzufügen <IonIcon slot="end" icon={arrowForward}></IonIcon></IonButton>
                </div>
            </IonSlide>
        </IonSlides>
    )
}