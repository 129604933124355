import React, { useState } from 'react';
import { IonButton, IonHeader, IonToolbar, IonButtons, IonCard, IonModal, IonContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonImg } from '@ionic/react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useEffect } from "react";
import { useParams } from 'react-router';

interface AngleModalState {
    angleModalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export const AngleExplanation: React.FC<AngleModalState> = ({ angleModalState: [angleModalOpen, setAngleModalOpen] }) => {
    return (
        <div>
            <IonModal
                isOpen={angleModalOpen}
                swipeToClose={true}
                onDidDismiss={() => setAngleModalOpen(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setAngleModalOpen(false)}>
                                Schließen
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonImg src="https://www.montana-international.com/fileadmin/_processed_/9/0/csm_Griptech_Mikrosaege_ceabccd652.jpg" />
                                    <IonCardHeader>
                                        <IonCardTitle>Kantenwinkel</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        Winkel von 90°-86° sind in 0,5° Schritten möglich. Je spitzer der Winkel, desto schärfer wird die Kante. 
                                        Eine scharfe Kante eignet sich besonders für eine sportliche Fahrweise. Der Ski wird agressiver. 
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>86°-88°</IonCardTitle>
                                        <IonCardSubtitle>Profi</IonCardSubtitle>
                                    </IonCardHeader>
                                </IonCard>
                            </IonCol>
                            <IonCol>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>88°-89°</IonCardTitle>
                                        <IonCardSubtitle>Fortgeschrittene</IonCardSubtitle>
                                    </IonCardHeader>
                                </IonCard>
                            </IonCol>
                            <IonCol>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>89°-90°</IonCardTitle>
                                        <IonCardSubtitle>Anfänger</IonCardSubtitle>
                                    </IonCardHeader>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
            </IonModal>
        </div>
    );
};

export default AngleExplanation;



