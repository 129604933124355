//@ts-nocheck

import './Home.css';
import { IonButton, IonPage, IonProgressBar, IonHeader, IonToolbar, IonButtons, IonCard, IonModal, IonDatetime, IonContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonImg, IonItem, IonInput, IonLabel, IonSelect, IonSelectOption, IonNav, useIonRouter, IonLoading, IonTitle, IonMenuButton, IonIcon, IonFooter } from '@ionic/react';
import { IonGrid, IonRow, IonCol, IonAccordion, IonAccordionGroup, IonList, IonSlide, IonSlides } from '@ionic/react';
import { useEffect, useState, useContext } from "react";
import './CheckIn.css';
import createAuth0Helper from '../components/Auth0Helper';
import { arrowForward } from 'ionicons/icons';
import { AppContext } from '../State';
import { SkiRentalProvider, useSkiRentalItemContext } from '../RentalState';
import { ServiceProvider } from '../ServiceState';
import { WelcomeSlides } from '../components/WelcomeSlides';
import { SkiRentalItemOverview } from '../components/SkiRentalItemOverview';
import { SkiRentalDialog } from '../components/SkiRentalDialog';
import { ServiceDialog } from '../components/ServiceDialog';
import { ServiceOverview } from '../components/ServiceOverview';
import { SkiRentalItemAdd } from '../components/SkiRentalItemAdd';
import { ServiceItemAdd } from '../components/ServiceItemAdd';
import { OpenCheckout } from '../components/OpenCheckout';
import { SkiRentalCheckoutDialog } from '../components/SkiRentalCheckoutDialog';

const Home: React.FC = () => {

  const [user, setUser] = useState({
    given_name: String,
    family_name: String,
  });

  const [rental, setRental] = useState(null);

  const [showLoading, setShowLoading] = useState(true);

  const router = useIonRouter();

  let auth0Helper = createAuth0Helper();

  const slideOpts = {
    initialSlide: 0,
    speed: 400
  };

  useEffect(async () => {
    auth0Helper.getUserInfo(function (userInfo, err) {
      if (err) {
        router.push("/login");
      } else {
        setUser(userInfo);
        setShowLoading(false);
      }
    });
  }, [])

  if (showLoading) {
    return (
      <IonPage>
        <IonContent fullscreen scroll-y="true">
          <IonLoading
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={'Bitte warten...'} />
        </IonContent>
      </IonPage>
    )
  } else {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="secondary">
              <IonMenuButton autoHide={false}></IonMenuButton>
            </IonButtons>
            <IonTitle size="large">Startseite</IonTitle>
          </IonToolbar>
        </IonHeader>
        <SkiRentalProvider>
          <ServiceProvider>
          <IonContent fullscreen scroll-y="true">
            <WelcomeSlides />

            <SkiRentalDialog />

            <SkiRentalItemOverview />

            <SkiRentalCheckoutDialog />

            <ServiceDialog />

            <ServiceOverview />

          </IonContent>
          <IonFooter>
          <ServiceItemAdd />
          <SkiRentalItemAdd />
          <OpenCheckout />
          </IonFooter>
          </ServiceProvider>
        </SkiRentalProvider>
      </IonPage>
    );
  }
};

export default Home;
