import React, { useDebugValue, useState } from 'react';
import { IonButton, IonHeader, IonToolbar, IonButtons, IonCard, IonModal, IonContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonImg } from '@ionic/react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import QRCode from 'react-qr-code';

interface ModalState {
    modalState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    value: any;
}

export const QRModal: React.FC<ModalState> = ({ modalState: [modalShown, setModalShown]}, value) => {
    return (
        <div>
            <IonModal
                isOpen={modalShown}
                swipeToClose={true}
                onDidDismiss={() => setModalShown(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setModalShown(false)}>
                                Schließen
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <QRCode value={value}/>
                </IonContent>
            </IonModal>
        </div>
    );
};

export default QRModal;