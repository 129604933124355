import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect } from "react";
import createAuth0Helper, { Auth0Helper } from '../components/Auth0Helper';


const Login: React.FC = () => {

  let auth0Helper = createAuth0Helper();

  useEffect(() => {
    window.addEventListener('load', async () => {
      auth0Helper.loginWithRedirect();
    });
  }, []);

  return (
    <IonPage>
      <IonHeader>
      </IonHeader>
      <IonContent>
    </IonContent>
  </IonPage>
  );
};

export default Login;
