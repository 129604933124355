
import * as React from "react";
import { IonButton, useIonRouter, IonIcon, IonSlides, IonSlide, IonToolbar, IonFooter } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { SkiRentalItem, useSkiRentalItemContext } from "../RentalState";

export const OpenCheckout: React.FC = () => {
    const { skiRentalItems, setCheckoutShown } = useSkiRentalItemContext();

    return (
            <IonToolbar hidden={skiRentalItems.length == 0}>
                <IonButton color="success" expand="block" size="large" onClick={e => {setCheckoutShown(true)}}>Zum Checkout</IonButton>
            </IonToolbar>
    )
}