import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import PartnerCheckIn from '../components/PartnerCheckIn';
import './CheckIn.css';

const CheckIn: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen scroll-y="true">
        <IonToolbar class="ion-toolbar-montana">
        </IonToolbar>
        <PartnerCheckIn/>
      </IonContent>
    </IonPage>
  );
};

export default CheckIn;
