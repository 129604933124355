
//@ts-nocheck
import * as React from "react";
import { IonButton, useIonRouter, IonIcon, IonSlides, IonSlide, IonModal, IonContent, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonInput, IonSegment, IonSegmentButton, IonLabel, IonHeader, IonTitle, IonToolbar, IonButtons } from '@ionic/react';
import { addOutline, arrowForward, ellipseOutline, removeOutline } from 'ionicons/icons';
import { Age, Skill, Skill2, SkiRentalItem, useSkiRentalItemContext } from "../RentalState";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";

export const SkiRentalDialog: React.FC = () => {
    const { skiRentalItems, skiRentalItemsDialogShown, setSkiRentalItemsDialogShown, addSkiRentalItem } = useSkiRentalItemContext();

    const {
        handleSubmit,
        control,
        setValue,
        register,
        getValues,
        formState: { errors }
    } = useForm<{
        weight: Number;
        height: Number;
        skill: Skill;
        skill2: Skill2;
        age: Age;
    }>({
        defaultValues: {
            weight: '',
            height: '',
            skill: Skill.beginner,
            skill2: Skill2.neutral,
            age: Age.age10to49,
        }
    });

    const onSubmit = async (data: any) => {
        addSkiRentalItem("Max Mustermann", data.weight, data.height, data.skill, data.skill2, data.age);
        setSkiRentalItemsDialogShown(false);
    };

    return (
        <IonModal isOpen={skiRentalItemsDialogShown}>
            <IonHeader translucent>
                <IonToolbar>
                    <IonTitle>Angaben zur Person</IonTitle>
                    <IonButtons slot="secondary">
                        <IonButton onClick={e => setSkiRentalItemsDialogShown(false)}>Schließen</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <IonCard>
                    <IonCardContent>
                        Wir benötigen die folgenden Informationen, um Ihre Bindungen für Ihre persönlichen Bedürfnisse anzupassen.<br />
                        Der Artikel ist nicht für Sie? Dann geben Sie hier bitte die Daten des Skifahrers an.
                    </IonCardContent>
                </IonCard>

                <IonCardHeader>
                    <IonCardTitle>Gewicht [kg]</IonCardTitle>
                </IonCardHeader>
                <IonItem>
                    <IonInput
                        placeholder='85'
                        type="number"
                        inputMode="numeric"
                        {...register('weight', {
                            required: 'Diese Angabe wird benötigt.',
                        })}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="weight"
                        as={<div style={{ color: 'red' }} />}
                    />
                </IonItem>

                <IonCardHeader>
                    <IonCardTitle>Körpergröße [cm]</IonCardTitle>
                </IonCardHeader>
                <IonItem>
                    <IonInput
                        placeholder='175'
                        type="number"
                        inputMode="numeric"
                        {...register('height', {
                            required: 'Diese Angabe wird benötigt.',
                        })}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="height"
                        as={<div style={{ color: 'red' }} />}
                    />
                </IonItem>

                <IonCardHeader>
                    <IonCardTitle>Skifahrertyp</IonCardTitle>
                </IonCardHeader>

                <IonSegment value={Skill.beginner}>
                    <IonSegmentButton value={Skill.beginner} class='ion-segment-button-large'>
                        <IonLabel>Anfänger</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value={Skill.advanced} class='ion-segment-button-large'>
                        <IonLabel>Fortgeschritten</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value={Skill.professional} class='ion-segment-button-large'>
                        <IonLabel>Profi</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                <IonSegment value="2">
                    <IonSegmentButton value="1" layout="icon-start">
                        <IonIcon icon={addOutline} />
                    </IonSegmentButton>
                    <IonSegmentButton value="2" layout="icon-start">
                        <IonIcon icon={ellipseOutline} />
                    </IonSegmentButton>
                    <IonSegmentButton value="3" layout="icon-start">
                        <IonIcon icon={removeOutline} />
                    </IonSegmentButton>
                </IonSegment>

                <IonCardHeader>
                    <IonCardTitle>Alter</IonCardTitle>
                </IonCardHeader>

                <IonSegment value="1">
                    <IonSegmentButton value="1" class='ion-segment-button-large'>
                        <IonLabel>0-9</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="2" class='ion-segment-button-large'>
                        <IonLabel>10-49</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="3" class='ion-segment-button-large'>
                        <IonLabel>50+</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

            <IonButton type="submit" size="large" expand="block" >Hinzufügen</IonButton>
            </form>
            </IonContent>
        </IonModal>
    )
}