
//@ts-nocheck
import * as React from "react";
import { IonButton, useIonRouter, IonIcon, IonSlides, IonSlide, IonModal, IonContent, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonItem, IonInput, IonSegment, IonSegmentButton, IonLabel, IonHeader, IonTitle, IonToolbar, IonButtons } from '@ionic/react';
import { addOutline, arrowForward, ellipseOutline, removeOutline } from 'ionicons/icons';
import { Age, Skill, Skill2, SkiRentalItem, useSkiRentalItemContext } from "../RentalState";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { useServiceItemContext, Service } from "../ServiceState";

export const ServiceDialog: React.FC = () => {
    const { serviceItem, serviceItemDialogShown, setServiceItemDialogShown, setNewServiceItem, load } = useServiceItemContext();

    const {
        handleSubmit,
        control,
        setValue,
        register,
        getValues,
        formState: { errors }
    } = useForm<{
        skiId: String,
        reference: String,
        article: Service,
        customerName: String,
        comment: String,
    }>({
        defaultValues: {
            skiId: '',
            reference: '',
            article: Service.regular,
            customerName: '',
            comment: '',
        }
    });

    const onSubmit = async (data: any) => {
        setNewServiceItem(data.customerName, data.skiId, data.reference, data.comment, data.article);
        setServiceItemDialogShown(false);
        load();
    };

    return (
        <IonModal isOpen={serviceItemDialogShown}>
            <IonHeader translucent>
                <IonToolbar>
                    <IonTitle>Angaben zum Service</IonTitle>
                    <IonButtons slot="secondary">
                        <IonButton onClick={e => setServiceItemDialogShown(false)}>Schließen</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={true}>
            <form onSubmit={handleSubmit(onSubmit)}>
            <IonCardHeader>
                <IonCardTitle>Name</IonCardTitle>
                </IonCardHeader>
                <IonItem>
                    <IonInput
                        placeholder='Max Mustermann'
                        type="string"
                        inputMode="text"
                        {...register('customerName', {
                            required: 'Diese Angabe wird benötigt.',
                        })}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="customerName"
                        as={<div style={{ color: 'red' }} />}
                    />
                </IonItem>

                <IonCardHeader>
                    <IonCardTitle>Ski-Kennung</IonCardTitle>
                </IonCardHeader>
                <IonItem>
                    <IonInput
                        placeholder='1282074'
                        type="string"
                        inputMode="text"
                        {...register('skiId')}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="skiId"
                        as={<div style={{ color: 'red' }} />}
                    />
                </IonItem>

                <IonCardHeader>
                    <IonCardTitle>Abholschein-Nummer</IonCardTitle>
                </IonCardHeader>
                <IonItem>
                    <IonInput
                        placeholder='110160134175'
                        type="string"
                        inputMode="text"
                        {...register('reference', {
                            required: 'Diese Angabe wird benötigt.',
                        })}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="reference"
                        as={<div style={{ color: 'red' }} />}
                    />
                </IonItem>

                <IonCardHeader>
                    <IonCardTitle>Service</IonCardTitle>
                </IonCardHeader>

                <IonSegment 
                    {...register('article')} 
                    value={Service.small}
                    onIonChange={e => setValue('article', e.detail.value)}
                    >
                    <IonSegmentButton value={Service.small}  class='ion-segment-button-large'>
                        <IonLabel>Klein</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value={Service.full} class='ion-segment-button-large'>
                        <IonLabel>Komplett</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value={Service.race} class='ion-segment-button-large'>
                        <IonLabel>Rennservice</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value={Service.wax} class='ion-segment-button-large'>
                        <IonLabel>Wachs</IonLabel>
                    </IonSegmentButton>
                </IonSegment>

                <IonCardHeader>
                    <IonCardTitle>Kommentar</IonCardTitle>
                </IonCardHeader>
                <IonItem>
                    <IonInput
                        placeholder='Bitte darauf achten, dass ...'
                        type="string"
                        inputMode="text"
                        {...register('comment')}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="comment"
                        as={<div style={{ color: 'red' }} />}
                    />
                </IonItem>

            <IonButton type="submit" size="large" expand="block">Hinzufügen</IonButton>
            </form>
            </IonContent>
                
        </IonModal>
    )
}