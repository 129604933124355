import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from './pages/Home';

import CheckIn from './pages/CheckIn';
import CheckInCallback from './pages/CheckInCallback';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Service from './pages/Service';
import Rental from './pages/Rental';
import SkiserviceLandingPage from './pages/SkiserviceLandingPage';
import Login from './pages/Login';

setupIonicReact({
    mode: 'md',
  }
);

const App: React.FC = () => (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/q/:skiid">
            <SkiserviceLandingPage />
          </Route>
          <Route exact path="/check-in/:partnerid">
            <CheckIn />
          </Route>
          <Route exact path="/check-in/callback">
            <CheckInCallback />
          </Route>
          <Route exact path="/service">
            <Service />
          </Route>
          <Route exact path="/rental">
            <Rental />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/">
            <Home />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
);

export default App;
