import * as React from "react";

// Standard interface and functions
export interface SkiRentalItem {
  id: number;
  weight: number;
  height: number;
  skill: Skill;
  skill2: Skill2;
  age: Age;
  owner: String;
}

export enum Skill {
  beginner, advanced, professional
}

export enum Skill2 {
  plus, neutral, minus
}

export enum Age {
  age0to9, age10to49, age50plus
}

const updateSkiRentalItem = (skiRentalItems: SkiRentalItem[], id: number, text: string): SkiRentalItem[] =>
skiRentalItems.map((skiRentalItem) => ({
    ...skiRentalItem,
    //text: skiRentalItem.id === id ? text : skiRentalItem.text,
  }));

const submit = async (callback: any, data: SkiRentalItem[]) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  };
  const response = await fetch('https://my.montana-digital.com/api/rental', requestOptions);
  const responseData = response.json();
  callback(responseData);
}

const removeSkiRentalItem = (skiRentalItems: SkiRentalItem[], id: number): SkiRentalItem[] =>
skiRentalItems.filter((skiRentalItem) => skiRentalItem.id !== id);

const addSkiRentalItem = (skiRentalItems: SkiRentalItem[], owner: String, weight: number, height: number, skill: Skill, skill2: Skill2, age: Age): SkiRentalItem[] => [
  ...skiRentalItems,
  {
    id: Math.max(0, Math.max(...skiRentalItems.map(({ id }) => id))) + 1,
    owner,
    weight,
    height,
    skill,
    skill2,
    age,
  },
];

// Custom hook implementation
const useSkiRentalItems = (initial: SkiRentalItem[] = []) => {
  const [skiRentalItems, setSkiRentalItems] = React.useState<SkiRentalItem[]>(initial);
  const [newSkiRentalItem, setNewSkiRentalItem] = React.useState<string>("");
  const [skiRentalItemsDialogShown, setSkiRentalItemsDialogShown] = React.useState(false);
  const [checkoutShown, setCheckoutShown] = React.useState(false);

  return {
    skiRentalItems,
    newSkiRentalItem,
    skiRentalItemsDialogShown,
    setSkiRentalItemsDialogShown,
    checkoutShown,
    setCheckoutShown,
    setNewSkiRentalItem,
    addSkiRentalItem(owner: String, weight: number, height: number, skill: Skill, skill2: Skill2, age: Age) {
      setSkiRentalItems((tl) => addSkiRentalItem(tl, owner, weight, height, skill, skill2, age));
    },
    updateSkiRentalItem: (id: number, text: string) =>
      setSkiRentalItems((tl) => updateSkiRentalItem(tl, id, text)),
    removeSkiRentalItem: (id: number) => setSkiRentalItems((tl) => removeSkiRentalItem(tl, id)),
    load: (newSkiRentalItems: SkiRentalItem[]) => setSkiRentalItems(newSkiRentalItems),
    submit: () => submit((data: any) => {
      console.log(data);
      setCheckoutShown(false);
    }, skiRentalItems),
  };
};

const SkiRentalItemContext = React.createContext<ReturnType<typeof useSkiRentalItems> | null>(null);

export const useSkiRentalItemContext = () => React.useContext(SkiRentalItemContext)!;

export function SkiRentalProvider({ children }: { children: React.ReactNode }) {
  return (
    <SkiRentalItemContext.Provider value={useSkiRentalItems([])}>{children}</SkiRentalItemContext.Provider>
  );
}