import React, { useState } from 'react';
import { IonButton, IonHeader, IonToolbar, IonButtons, IonCard, IonModal, IonContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonImg } from '@ionic/react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useEffect } from "react";
import { useParams } from 'react-router';
import { AngleExplanation } from './AngleExplanation';

export const SkiService: React.FC = () => {

  const [skiservice, setSkiservice] = useState({
    date: String,
    angle: String,
    ways: String,
    finish: String,
    recipe: String,
    store_name: String,
    customer_name: String,
  });

  const [angleModalOpen, setAngleModalOpen] = useState(false);

  let { skiid } = useParams<{ skiid: string }>();

  useEffect(() => {
    fetchData();

    async function fetchData() {
      return fetch("/api/skiservice/" + skiid)
        .then((response) => response.json())
        .then((data) => setSkiservice(data[0]));
    }
  }, [setSkiservice]);

  return (
    <div>
      <AngleExplanation angleModalState={[angleModalOpen, setAngleModalOpen]} />
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonCard>
              <IonImg src="https://www.montana-international.com/fileadmin/user_upload/images/titelbilder/001.jpg" />
              <IonCardHeader>
                <IonCardTitle>Montana GripTech</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                Mit dem MONTANA GRIPtech System erfolgt der Schliff in vertikaler Richtung.
                So entsteht eine präzise, scharfe und lang­lebige Kante.
                Das Sportgerät wird ohne Einlaufspuren schonend über alle Funktions­punkte von Anfang bis Ende in RACE EDGE Qualität geschliffen.
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{skiservice.date}</IonCardTitle>
                <IonCardSubtitle>Letzer Service</IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{skiservice.customer_name}</IonCardTitle>
                <IonCardSubtitle>{skiservice.store_name}</IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonCard onClick={() => { setAngleModalOpen(true) }}>
              <IonCardHeader>
                <IonCardTitle>{skiservice.angle}°</IonCardTitle>
                <IonCardSubtitle>Kantenwinkel</IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonCol>
          <IonCol>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{skiservice.ways}</IonCardTitle>
                <IonCardSubtitle>Schleifwege</IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{skiservice.finish}</IonCardTitle>
                <IonCardSubtitle>Finish</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                Unser Race Finish lässt an den Kanten Ihrer Ski einen schmalen Grat entstehen, der Ihnen noch mehr Grip gibt.
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{skiservice.recipe}</IonCardTitle>
                <IonCardSubtitle>Schleifrezept</IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonCard>
              <IonImg src="https://www.montana-international.com/fileadmin/_processed_/0/0/csm_steinschliff_4_59905c3069.jpg" />
              <IonCardHeader>
                <IonCardTitle>Montana VARIO</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                Der legendäre MONTANA Steinschliff sorgt für exakt geschnittene Strukturen. Die MONTANA VARIO Strukturen ermöglichen unterschiedliche Strukturbilder über die Breite des Sportgerätes.
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonCard>
              <IonImg src="https://www.montana-international.com/fileadmin/user_upload/images/maschinen/roboter/Crystal-Magic_web.jpg" />
              <IonCardHeader>
                <IonCardTitle>Crystal Magic</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                Der neue CRYSTAL MAGIC Roboter besticht durch Technik auf höchstem Niveau mit unschlagbarer Modularität: 19 Basisvarianten sorgen für eine optimale Konfiguration nach den Anforderungen der Werkstatt. Mit ihrem breiten Stein ist die CRYSTAL MAGIC die optimale Lösung, wenn Ski und Snowboards auf einem Roboter bearbeitet werden sollen. Wahlweise sind 1 oder 2 Steine erhältlich.
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default SkiService;