import * as React from "react";
import createAuth0Helper from './components/Auth0Helper';

let auth0Helper = createAuth0Helper();

// Standard interface and functions
export interface ServiceItem {
  id: number;
  skiId: String;
  articleId: number;
  articleName: String;
  recipe: String;
  customerId: String;
  customerName: String;
  reference: String;
  comment: String;
  status: String;
  closedDate: String;
}

export enum Service {
  small, full, race, wax
}

export interface ServiceType {
  articleId: number;
  articleName: String;
}

const submit = async (callback: any, data: ServiceItem) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' +  auth0Helper.getAccessToken()},
    body: JSON.stringify(data)
  };
  const response = await fetch('https://my.montana-digital.com/api/orders', requestOptions);
  const responseData = response.json();
  callback(responseData);
}

const submitServiceItem = (serviceItem: ServiceItem) => {
  submit(() => {}, serviceItem);
}

const addServiceItem = (serviceItems: ServiceItem[], serviceItem: ServiceItem): ServiceItem[] => [
  ...serviceItems, serviceItem];

const load = async (callback: any) => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' +  auth0Helper.getAccessToken()}
  };
  const response = await fetch('https://my.montana-digital.com/api/orders?limit=20', requestOptions);
  const responseData: Array<ServiceItem> = await response.json();
  callback(responseData);
}

// Custom hook implementation
const useServiceItem = (initial: ServiceItem = {
  id: 0,
  skiId: "undefined",
  articleId: 0,
  articleName: "undefined",
  recipe: "undefined",
  customerId: "undefined",
  customerName: "undefined",
  reference: "undefined",
  status: "OPEN",
  comment: "undefined",
  closedDate: "",
}) => {
  const [serviceItem, setServiceItem] = React.useState<ServiceItem>(initial);
  const [serviceItems, setServiceItems] = React.useState<ServiceItem[]>([]);
  const [serviceItemDialogShown, setServiceItemDialogShown] = React.useState(false);

  return {
    serviceItem,
    setNewServiceItem(customerName: String, skiId: String, reference: String, comment: String, article: Service) {
      let tmp = {
        id: Math.max(0, Math.max(...serviceItems.map(({ id }) => id))) + 1,
        skiId: skiId,
        articleId: article,
        articleName: Service[article],
        recipe: "",
        customerId: "",
        customerName: customerName,
        reference: reference,
        status: "OPEN",
        comment: comment,
        closedDate: "",
      }
      setServiceItem(tmp);
      submit(() => {}, tmp);
      //addServiceItem(serviceItems, tmp);
    },
    submitNewServiceItem() {
      submitServiceItem(serviceItem);
    },
    serviceItemDialogShown,
    setServiceItemDialogShown,
    serviceItems,
    load() {
      load(setServiceItems);
    }
  }
}

const ServiceItemContext = React.createContext<ReturnType<typeof useServiceItem> | null>(null);

export const useServiceItemContext = () => React.useContext(ServiceItemContext)!;

export function ServiceProvider({ children }: { children: React.ReactNode }) {
  return (
    <ServiceItemContext.Provider value={useServiceItem()}>{children}</ServiceItemContext.Provider>
  );
}