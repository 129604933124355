//@ts-nocheck
import React, { useState } from 'react';
import { IonButton, IonProgressBar, IonHeader, IonToolbar, IonButtons, IonCard, IonModal, IonDatetime, IonContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonImg, IonItem, IonInput, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm, useController } from "react-hook-form";
import { useParams } from 'react-router';
import { ErrorMessage } from '@hookform/error-message';
import createAuth0Helper from '../components/Auth0Helper';

export const PartnerCheckIn: React.FC = () => {

    let { partnerid } = useParams<{ partnerid: string }>();
    const [ submitSuccessful, setSubmitSuccessful ] = useState<boolean>(false);
    const {
        handleSubmit,
        control,
        setValue,
        register,
        getValues,
        formState: { errors }
    } = useForm({
        defaultValues: {
            gender: '',
            email: '',
            given_name: '',
            family_name: '',
            street: '',
            zip: '',
            city: '',
            birth_dayOfMonth: '',
            birth_month: '',
            birth_year: '',
        }
    });

    const auth0Helper = createAuth0Helper();

    const onSubmit = async (data: any) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        const response = await fetch('https://my.montana-digital.com/api/check-in/' + partnerid, requestOptions);
        const responseData = await response.json();
        setSubmitSuccessful(true);
        auth0Helper.passwordlessStart(data.email);
    };

    return (
        <div>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonCard>
                            <IonImg src="https://www.montana-international.com/fileadmin/user_upload/images/titelbilder/001.jpg" />
                            <IonCardHeader>
                                <IonCardTitle>Express Check-In: {partnerid}</IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent>
                                Nutzen Sie unseren Express-Service und checken Sie bereits jetzt ein.
                            </IonCardContent>
                        </IonCard>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <IonItem>
                                <IonLabel position="floating">Vorname</IonLabel>
                                <IonInput
                                    autocomplete='given-name'
                                    autofocus='on'
                                    {...register('given_name', {
                                        required: 'Diese Angabe wird benötigt.',
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="given_name"
                                    as={<div style={{ color: 'red' }} />}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Nachname</IonLabel>
                                <IonInput
                                    autocomplete='family-name'
                                    {...register('family_name', {
                                        required: 'Diese Angabe wird benötigt.',
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="family_name"
                                    as={<div style={{ color: 'red' }} />}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Geschlecht</IonLabel>
                                <Controller
                                    render={({ field }) => (
                                        <IonSelect
                                            placeholder="Bitte auswählen"
                                            value={field.value}
                                            interface="action-sheet"
                                            onIonChange={e => setValue('gender', e.detail.value)}
                                        >
                                            <IonSelectOption value="FEMALE">weiblich</IonSelectOption>
                                            <IonSelectOption value="MALE">männlich</IonSelectOption>
                                        </IonSelect>
                                    )}
                                    control={control}
                                    name="gender"
                                    rules={{ required: 'Diese Angabe wird benötigt.' }}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="gender"
                                    as={<div style={{ color: 'red' }} />}
                                />
                            </IonItem>
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardTitle>Warum?</IonCardTitle>
                                </IonCardHeader>
                                <IonCardContent>
                                    Zu Ihrer Sicherheit stellen wir Bindungen nach DIN-Norm 11088 ein.
                                    Dazu ermitteln wir individuelle Auslösewerte anhand Geschlecht, Größe, Körpergewicht, Alter, Sohlenlänge und Skifahrer-Typ.
                                    Geben Sie hierzu das Geschlecht gem. ihrer Geburtsurkunde an.
                                </IonCardContent>
                            </IonCard>
                            <IonItem>
                                <IonLabel position="floating">Straße und Hausnummer</IonLabel>
                                <IonInput
                                    autocomplete='address-line1'
                                    {...register('street', {
                                        required: 'Diese Angabe wird benötigt.',
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="street"
                                    as={<div style={{ color: 'red' }} />}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Postleitzahl</IonLabel>
                                <IonInput
                                    autocomplete='postal-code'
                                    {...register('zip', {
                                        required: 'Diese Angabe wird benötigt.',
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="zip"
                                    as={<div style={{ color: 'red' }} />}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">Stadt</IonLabel>
                                <IonInput
                                    autocomplete='address-level1'
                                    {...register('city', {
                                        required: 'Diese Angabe wird benötigt.',
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="city"
                                    as={<div style={{ color: 'red' }} />}
                                />
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating">E-Mail Adresse</IonLabel>
                                <IonInput
                                    autocomplete='email'
                                    {...register('email', {
                                        required: 'Diese Angabe wird benötigt.',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Bitte geben Sie eine gültige E-Mail Adresse an.'
                                        }
                                    })}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="email"
                                    as={<div style={{ color: 'red' }} />}
                                />
                            </IonItem>
                            <IonCard>
                                <IonCardHeader>
                                    <IonCardSubtitle>Geburtstag</IonCardSubtitle>
                                </IonCardHeader>
                                <IonRow>
                                    <IonCol>
                                        <Controller
                                            render={({ field }) => (
                                                <IonSelect
                                                    placeholder="Tag"
                                                    interface="action-sheet"
                                                    value={field.value}
                                                    onIonChange={e => setValue('birth_dayOfMonth', e.detail.value)}
                                                >
                                                    <IonSelectOption value="01">01</IonSelectOption>
                                                    <IonSelectOption value="02">02</IonSelectOption>
                                                </IonSelect>
                                            )}
                                            control={control}
                                            name="birth_dayOfMonth"
                                            rules={{ required: 'Diese Angabe wird benötigt.' }}
                                        />
                                    </IonCol>
                                    <IonCol>
                                        <Controller
                                            render={({ field }) => (
                                                <IonSelect
                                                    placeholder="Monat"
                                                    value={field.value}
                                                    interface="action-sheet"
                                                    onIonChange={e => setValue('birth_month', e.detail.value)}
                                                >
                                                    <IonSelectOption value="01">Januar</IonSelectOption>
                                                    <IonSelectOption value="02">Februar</IonSelectOption>
                                                    <IonSelectOption value="03">März</IonSelectOption>
                                                    <IonSelectOption value="04">April</IonSelectOption>
                                                    <IonSelectOption value="05">Mai</IonSelectOption>
                                                    <IonSelectOption value="06">Juni</IonSelectOption>
                                                    <IonSelectOption value="07">Juli</IonSelectOption>
                                                    <IonSelectOption value="08">August</IonSelectOption>
                                                    <IonSelectOption value="09">September</IonSelectOption>
                                                    <IonSelectOption value="10">Oktober</IonSelectOption>
                                                    <IonSelectOption value="11">November</IonSelectOption>
                                                    <IonSelectOption value="12">Dezember</IonSelectOption>
                                                </IonSelect>
                                            )}
                                            control={control}
                                            name="birth_month"
                                            rules={{ required: 'Diese Angabe wird benötigt.' }}
                                        />
                                    </IonCol>
                                    <IonCol>
                                        <Controller
                                            render={({ field }) => (
                                                <IonSelect
                                                    placeholder="Jahr"
                                                    value={field.value}
                                                    interface="action-sheet"
                                                    onIonChange={e => setValue('birth_year', e.detail.value)}
                                                >
                                                    <IonSelectOption value="2000">2000</IonSelectOption>
                                                    <IonSelectOption value="2001">2001</IonSelectOption>
                                                </IonSelect>
                                            )}
                                            control={control}
                                            name="birth_year"
                                            rules={{ required: 'Diese Angabe wird benötigt.' }}
                                        />
                                    </IonCol>
                                </IonRow>

                                <ErrorMessage
                                    errors={errors}
                                    name="birth_dayOfMonth"
                                    as={<div style={{ color: 'red' }} />}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="birth_month"
                                    as={<div style={{ color: 'red' }} />}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="birth_year"
                                    as={<div style={{ color: 'red' }} />}
                                />
                            </IonCard>
                            <IonButton size="large" type="submit" expand="block">Einchecken</IonButton>
                        </form>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonModal isOpen={submitSuccessful}>
                <IonToolbar className="ion-toolbar-montana" />
                <IonCard>
                    <IonImg src="https://www.montana-international.com/fileadmin/user_upload/images/titelbilder/001.jpg" />
                    <IonProgressBar type="indeterminate"></IonProgressBar>
                    <IonCardHeader>
                        <IonCardSubtitle>Wir sind fast fertig.</IonCardSubtitle>
                        <IonCardTitle>Überprüfen Sie Ihren E-Mail Posteingang.</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        Sie haben eine Nachricht erhalten. Folgen Sie den Anweisungen um den Check-In abzuschließen.
                    </IonCardContent>
                </IonCard>
            </IonModal>
        </div>
    );
};

export default PartnerCheckIn;