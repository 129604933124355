//@ts-nocheck
import { useIonRouter } from '@ionic/react';
import { useEffect } from "react";
import createAuth0Helper from '../components/Auth0Helper';
import './CheckIn.css';

const CheckIn: React.FC = () => {

  const router = useIonRouter();

  let auth0Helper = createAuth0Helper();

  useEffect(async () => {
    window.addEventListener('load', async () => {
      auth0Helper.handleRedirectCallback({ hash: window.location.hash }, function (err: any) {
        if (err != null) {
          router.push("/login")
          return console.log(err);
        } else {
          router.push("/")
        }
      });
    });
  })

  return (<></>);

};

export default CheckIn;
